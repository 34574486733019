import * as React from 'react';
import { Route, Switch } from "react-router-dom"
import { AppSection } from '../../components/AppSection';
import { MarkdownContent } from '../../components/MarkdownContent';
import { MainPadding } from '../../components/MainPadding';
import { Paper } from '@material-ui/core';
import { SafeUrlGenerator } from './components/SafeUrlGenerator';

export class Tools extends React.Component<any, any>{

  public render() {
    return (<React.Fragment>
      <Switch>
      <Route path={"/tools/documentation"} exact={true} render={this.documentation} />
        <Route path={"/tools/safe-url-gen"} exact={true} render={this.renderSafeUrlGen} />
        <Route path={"/tools/*"} render={this.renderMarkdownPage} />
      </Switch>
    </React.Fragment>)
  }

  private renderMarkdownPage = ({ match }: any) => {
    let mPath = (match.params[0] || '').replace(/(^\/|\/$)/g, '');
    let path: string = '/md/' + mPath + '.md';
    return (<MainPadding key={match.params[0]}>
      <AppSection section={`tools/${mPath}`} />
      <Paper style={{ padding: 16 }}>
        <MarkdownContent path={path} /></Paper>
    </MainPadding>);
  }

  private renderSafeUrlGen = ({ match }: any) => {
    return (<MainPadding key={match.params[0]}>
      <AppSection section={`tools/safe-url-gen`} />
      <Paper style={{ padding: 16 }}>
        <MarkdownContent path={'/md/safe-url-gen.md'} />
        <SafeUrlGenerator />
      </Paper></MainPadding>);

  }

  private documentation = ({ match }: any) => {
    return(<MainPadding key={match.params[0]}>
      <AppSection section={`tools/documentation`} />
      <Paper style={{ padding: 16 }}>
        <a href="https://bit.ly/webdrvn-hugo-documentation" target='_blank'>Click here for Hugo Documentation </a>
      </Paper></MainPadding>)
  }
}