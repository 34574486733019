import * as React from 'react';
import { ListItem, ListItemText, Collapse, Theme, withStyles } from '@material-ui/core';
import { Home, Logs, LoginSmart as Login, NotFound, PrimeLendingWebsites, GenericWebsites, DynamicWebsites, SkeletonBootstrap, Users } from './scenes'
import ResponsiveDrawer from './components/ResponsiveDrawer';
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import { serviceLocator, AuthenticationService, UIService } from './services';
import { Quality } from './scenes/QualityReports';
import { InfraCredentials } from './scenes/InfraCredentials/InfraCredentials';
import { Agencies } from './scenes/Agencies/Agencies';
import { QualityConfigs } from './scenes/QualityCheckConfig/QualityCheckConfig';
import { Database } from './scenes/Database';
import { Tools } from './scenes/Tools';
import { AgencyPanel } from './scenes/AgencyPanel/Index';
import { AgencyMembers } from './scenes/AgencyPanel/Members';
import { AgencyGeneral } from './scenes/AgencyPanel/General';
import { AuthenticatedUserData } from "./services/authentication-service/types";


const authenticationService = serviceLocator.getAuthenticationService();
const uiService = serviceLocator.getUIService();

const styles = (theme: Theme) => ({
  subItem: {
    paddingLeft: '32px'
  }
});

interface AppProps {
  classes: any;
}

interface AppState {
  expandedSection: string;
}

class App extends React.Component<AppProps, AppState> {

  state: AppState = {
    expandedSection: ''
  }

  private linkComponents: { [path: string]: (props: any) => any } = {};
  private primaryTypographyProps: any = { color: "primary" };

  listItem: (props: { to: string, text: string, section: string, sub?: boolean }) => any = (props: any) => {
    let linkComponent: any = this.linkComponents[props.to];
    if (linkComponent == null) {
      linkComponent = React.forwardRef((p: any, ref: any) => (<Link to={props.to} {...p} ref={ref} />));
      this.linkComponents[props.to] = linkComponent;
    }
    const active = props.section === uiService.getActiveSection();
    return (
      <ListItem className={props.sub ? this.props.classes.subItem : ''} button={true} component={linkComponent as any}>
        <ListItemText primary={props.text} primaryTypographyProps={active ? this.primaryTypographyProps : undefined} />
      </ListItem>
    );
  }

  constructor(props: AppProps) {
    super(props);
    uiService.setForceUpdateHandler(() => this.forceUpdate());
  }

  public componentDidMount() {
    const authService = serviceLocator.getAuthenticationService();
    authService.setUnathorizedHandler(() => {
      authService.signOut();
      window.location.assign('/');
    });
    authService.setForbiddenHandler(() => {

    });
  }

  public componentWillUnmount() {
    const authService = serviceLocator.getAuthenticationService();
    authService.setUnathorizedHandler(null);
    authService.setForbiddenHandler(null);
  }

  handleSectionClick = (section: any) => (e: React.SyntheticEvent) => {
    e.stopPropagation();
    this.setState({ expandedSection: this.state.expandedSection === section ? '' : section })
  }

  handleAdminSectionClick = this.handleSectionClick('admin');
  handleToolsSectionClick = this.handleSectionClick('tools');
  // handleDocsSectionClick = this.handleSectionClick('docs');
  handleWebsitesSectionClick = this.handleSectionClick('websites');
  handleThemePanelSectionClick = this.handleSectionClick('themePanel');

  public render() {

    const authUserData = authenticationService.getAuthenticatedUserData();

    return (
      <Router>
        <Switch>
          {authUserData == null ? (<Route path="*" component={Login} />) : (null)}
          <Route path="*" render={this.renderAuthenticatedContent(authUserData)} />
        </Switch>
      </Router>
    );
  }

  private renderAuthenticatedContent = (authUserData: AuthenticatedUserData | null) => () => {
    if (authUserData) {
      const selectedAgency = uiService.getSelectedAgency() || 'all';
      const selectedAgencyData = authUserData.containedAgencies ? authUserData.containedAgencies.find(item => item.identifier == selectedAgency) : false
      const isAgent = selectedAgencyData ? selectedAgencyData.users.some(item => item.AgencyUser.role == 'agent') : false;
      const loggedUser = authUserData.username;
      const isAdmin = authUserData.roles.indexOf('admin') !== -1;
      const hasAgency = authUserData.ownedAgency == selectedAgency;
      const MenuListItem = this.listItem;

      console.log('authUserData',isAgent, selectedAgencyData, selectedAgency, authUserData)

      return (
        <ResponsiveDrawer
          key={`agency:${uiService.getSelectedAgency()}`}
          listItems={(<React.Fragment>
            <MenuListItem to={'/'} text="Home" section="home" />
            <ListItem button={true} onClick={this.handleWebsitesSectionClick} key="websites">
              <ListItemText primary={"Websites"} />
            </ListItem>
            <Collapse in={this.state.expandedSection === 'websites'}>
              {/* { isAdmin && <MenuListItem  to={'/websites/prime-lending/'} sub={true} text="PL Websites" section="websites/prime-lending" /> } */}
              {/* <MenuListItem to={'/websites/simple-business/'} sub={true} text="Business Websites" section="websites/simple-business" /> */}
              <MenuListItem to={'/websites/custom-website/'} sub={true} text="Custom Website" section="websites/custom-website" />
              <MenuListItem to={'/websites/skeleton-bootstrap-5-2/'} sub={true} text="Bootstrap 5.2" section="websites/skeleton-bootstrap-5-2" />
            </Collapse>

            {
              (isAdmin || isAgent || hasAgency) &&
              <>
                <ListItem button={true} onClick={this.handleThemePanelSectionClick} key="themePanel">
                  <ListItemText primary={"Agency Panel"} />
                </ListItem>
                <Collapse in={this.state.expandedSection === 'themePanel'}>
                  <MenuListItem to={'/agency-panel/general/'} sub={true} text="General" section="agency-panel/general" />
                  <MenuListItem to={'/agency-panel/members/'} sub={true} text="Members" section="agency-panel/members" />
                  <MenuListItem to={'/agency-panel/footer/'} sub={true} text="Global Footer" section="agency-panel/footer" />
                </Collapse>
              </>
            }

            {(isAdmin || hasAgency) && (<React.Fragment>
              <ListItem button={true} onClick={this.handleAdminSectionClick} key={'administration'}>
                <ListItemText primary={"Administration"} />
              </ListItem>
              <Collapse in={this.state.expandedSection === 'admin'}>
                {isAdmin && (<MenuListItem to={'/agencies/'} text="Agencies" sub={true} section="agencies" />)}
                {isAdmin && (<MenuListItem to={'/database/'} text="Database" sub={true} section="database" />)}
                {(isAdmin || hasAgency) && (<MenuListItem to={'/infra-credentials/'} text="Infrastructure Credentials" sub={true} section="infra-credentials" />)}
                {isAdmin && <MenuListItem to={'/logs/'} text="Logs" section="logs" sub={true} />}
                {isAdmin && <MenuListItem to={'/quality/'} text="Quality Reports" section="quality-reports" sub={true} />}
                {(isAdmin || hasAgency) && <MenuListItem to={'/quality-check-configs/'} text="Quality Check Config" section="quality-reports-configs" sub={true} />}


                {isAdmin && (<MenuListItem to={'/users/'} text="Users" sub={true} section="users" />)}
              </Collapse>
            </React.Fragment>)}
            <ListItem button={true} onClick={this.handleToolsSectionClick} key="tools">
              <ListItemText primary={"Tools & Docs"} />
            </ListItem>
            <Collapse in={this.state.expandedSection === 'tools'}>
            <MenuListItem to={'/tools/documentation/'} sub={true} text="Documentation" section="tools/documentation" />
              <MenuListItem to={'/tools/wizards/'} sub={true} text="Wizards" section="tools/wizards" />
              <MenuListItem to={'/tools/safe-url-gen/'} sub={true} text="Safe URL Gen" section="tools/safe-url-gen" />
            </Collapse>
            <ListItem button={true} onClick={this.handleSignOut}>
              <ListItemText primary={"Sign Out"} secondary={this.trimUserName(loggedUser)} />
            </ListItem>
          </React.Fragment>)}
          content={<React.Fragment>
            <Switch>
              <Route path="/database/" component={Database} />
              <Route path="/websites/prime-lending/" component={PrimeLendingWebsites} />
              <Route path="/websites/custom-website/" component={GenericWebsites} />
              <Route path="/websites/simple-business/" component={DynamicWebsites} />
              <Route path="/websites/skeleton-bootstrap-5-2/" component={SkeletonBootstrap} />
              <Route path="/quality-check-configs/" component={QualityConfigs} />
              <Route path="/quality/" exact={true} component={Quality} />
              <Route path="/logs/" exact={true} component={Logs} />
              <Route path="/users/" component={Users} />
              <Route path="/infra-credentials/" component={InfraCredentials} />
              <Route path="/agencies/" component={Agencies} />
              <Route path="/tools/" component={Tools} />
              <Route path="/agency-panel/" component={AgencyPanel} />
              <Route path="/" exact={true} component={Home} />
              <Route path="*" component={NotFound} />
            </Switch>
          </React.Fragment>
          }
        />
      );

    }

  }

  private handleSignOut = (e: any) => {
    authenticationService.signOut();
    this.forceUpdate();
  }

  private trimUserName(userName: string) {
    if (userName.length > 20) {
      return userName.substring(0, 18) + '...';
    }
    return userName;
  }
}

export const AppSmart = withStyles(styles)(App);
